export const BASE_ALOFT_URL = process.env.REACT_APP_BASE_ALOFT_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
export const SANCTUM_TOKEN = process.env.REACT_APP_SANCTUM_TOKEN;

// Mapbox
export const MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY;

//Firebase
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

//reCAPTCHA
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

//Pusher
export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;
export const PUSHER_APP_ENV = process.env.REACT_APP_PUSHER_ENV;

//Sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

//Material UI
export const MUI_KEY = process.env.REACT_APP_MUI_KEY;
